.custom-scrollbar-sidebar::-webkit-scrollbar-thumb {
    background: #f06a22;
    border-radius: 10px;
}

.custom-scrollbar-sidebar::-webkit-scrollbar-track {
    background: #041737;
}

.custom-scrollbar-sidebar::-webkit-scrollbar {
    width: 5px;
}

.custom-scrollbar-horizontal::-webkit-scrollbar-thumb {
    background: #3b62ad;
    border-radius: 10px;
}

.custom-scrollbar-horizontal::-webkit-scrollbar-track {
    background: none;
}

.custom-scrollbar-horizontal::-webkit-scrollbar {
    height: 2px;
}

.custom-scrollbar-horizontal2::-webkit-scrollbar-thumb {
    background: #c2c2c2;
    border-radius: 10px;
}

.custom-scrollbar-horizontal2::-webkit-scrollbar-track {
    background: none;
}

.custom-scrollbar-horizontal2::-webkit-scrollbar {
    height: 2px;
}

body {
    /* background-color: #ededed; */
    overflow-x: hidden;
}

body::-webkit-scrollbar-thumb {
    background: #3b62ad;
    border-radius: 10px;
}

body::-webkit-scrollbar-track {
    background: white;
}

body::-webkit-scrollbar {
    width: 7px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

select:focus,
input:focus {
    outline: none;
}
