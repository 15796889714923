@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}
.wrap {
  background-color: transparent;
  background-image: linear-gradient(-110deg, #ff57a2 0%, #ffa564 100%);
}
@media screen and (min-width: 792px) {
  .webkit-center {
    text-align: -webkit-center;
  }
}
